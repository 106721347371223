<template>
  <div>
    <SfLink
      class="font-medium no-underline text-base border-b-4 py-5 block"
      :link="link"
    >
      {{
        label
      }}
    </SfLink>
  </div>
</template>
<style lang="scss" scoped>
  div {
    .sf-link {
      border-color: transparent;
    }
    .nuxt-link-active {
      border-color: var(--c-primary);
    }
  }
</style>
<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { SfLink } from '@storefront-ui/vue';

export default defineComponent({
  name: 'FeaturedCategory',
  components: {
    SfLink,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    link: {
      type: [String, Object],
      default: '',
    }
  }
});
</script>
