<template>
  <section class="headlessfooter bg-secondaryLight text-primary overflow-hidden">
    <div>
      <div class="nav grid grid-cols-1 md:grid-cols-5 lg:px-14 pt-8">
        <HeadlessFooterLogo />
        <HeadlessFooterColumn class="md:pt-8" :content="column1" />
        <HeadlessFooterColumn class="md:pt-8" :content="column2" />
        <HeadlessFooterColumn class="md:pt-8" :content="column3" />
        <HeadlessSocialColumn class="md:pt-8" :content="columnSocial" />
      </div>
      <HeadlessFooterFlowers />

    </div>
  </section>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import SvgImage from '~/components/General/SvgImage.vue'
  import HeadlessFooterColumn from '~/components/Headless/Footer/Column.vue'
  import HeadlessSocialColumn from '~/components/Headless/Footer/SocialColumn.vue'
  import HeadlessFooterFlowers from '~/components/Headless/Footer/Flowers.vue'
  import HeadlessFooterLogo from '~/components/Headless/Footer/Logo.vue'

  export default defineComponent({
    name: 'HeadlessFooter',
    components: {
      SvgImage,
      HeadlessFooterColumn,
      HeadlessSocialColumn,
      HeadlessFooterLogo,
      HeadlessFooterFlowers,
    },
    data() {
      return {
        column1: {
          titleLink: "/about",
          title: "About",
          items: [
            {link: "/chichester-florists", name: "Who we are", external: false},
            {link: "/contact-us", name: "Contact us", external: false},
          ]
        },
        column2: {
          titleLink: "/info",
          title: "Info",
          items: [
            {link: "local-suppliers", name: "Local suppliers", external: false},
            {link: "tel:01243 200133", name: "Call us", external: true},
            {link: "mailto:flowers@hedgerose.uk", name: "Email us", external: true},
            {link: "https://www.google.com/maps/place/Hedge+Rose/@50.8362483,-0.7971063,14z/data=!4m10!1m2!2m1!1shedgerose!3m6!1s0x487453ec040b5d11:0x398789b847211b5d!8m2!3d50.8362483!4d-0.7729935!15sCgloZWRnZXJvc2VaCyIJaGVkZ2Vyb3NlkgEHZmxvcmlzdOABAA!16s%2Fg%2F11fk8fhcrt", name: "Visit us", external: true},
          ]
        },
        column3: {
          titleLink: "/order",
          title: "Order",
          items: [
            {link: "/terms", name: "Purchase terms"},
          ]
        },
        columnSocial: {
          titleLink: "/socials",
          title: "Follow",
          items: [
            {icon: "facebook", label: "Facebook", link: 'https://www.facebook.com/hedgeroseuk/'},
            {icon: "twitter", label: "Twitter", link: 'https://twitter.com/hedgeroseuk'},
            {icon: "instagram", label: "Instagram", link: 'https://instagram.com/hedgeroseuk'},
          ]
        },
      }
    }
  });
</script>
