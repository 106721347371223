





































import {
  defineComponent, computed, onMounted,
} from '@nuxtjs/composition-api';
import {
  SfButton,
  SfList,
  SfBottomModal,
  SfCharacteristic,
} from '@storefront-ui/vue';
import {
  useCurrency,
} from '~/composables';

export default defineComponent({
  name: 'CurrenciesModal',
  components: {
    SfButton,
    SfList,
    SfBottomModal,
    SfCharacteristic,
  },
  props: {
    isModalOpen: Boolean,
    selectedCurrency: {
      type: String,
      default: '',
    },
  },
  emits: ['closeModal'],
  setup() {
    const {
      currency: currencies,
      change: changeCurrency,
      load: loadCurrencies,
    } = useCurrency();

    const availableCurrencies = computed<string[]>(() => currencies.value?.available_currency_codes || []);

    onMounted(() => {
      if (currencies.value && currencies.value?.available_currency_codes) return;
      loadCurrencies();
    });

    return {
      changeCurrency,
      availableCurrencies,
    };
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
  },
});
