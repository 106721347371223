<template>
  <svg width="529" height="519" viewBox="0 0 529 519" fill="none">
    <g opacity="0.45" filter="url(#filter0_f_25002_12505)">
      <path d="M458 279C458 377.859 377.859 458 279 458C180.141 458 100 377.859 100 279C100 180.141 180.141 100 279 100C377.859 100 458 180.141 458 279Z" fill="#EDD3CA"/>
      <path d="M373.166 227.675C373.166 279.916 330.817 322.265 278.576 322.265C226.335 322.265 183.986 279.916 183.986 227.675C183.986 175.435 226.335 133.085 278.576 133.085C330.817 133.085 373.166 175.435 373.166 227.675Z" fill="#EDD3CA"/>
    </g>
    <defs>
      <filter id="filter0_f_25002_12505" x="0" y="0" width="558" height="558" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur_25002_12505"/>
      </filter>
    </defs>
  </svg>
</template>
<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'CircleGradient',
});
</script>

