





















import { defineComponent, useAsync, useContext, ref, onMounted } from '@nuxtjs/composition-api';
import { SfButton, SfTopBar } from '@storefront-ui/vue';
import { useTopBar } from './useTopBar';
import HTMLContent from '~/components/HTMLContent.vue';
import { useCache, CacheTagPrefix } from '@vue-storefront/cache';

export default defineComponent({
  components: {
    SfTopBar,
    SfButton,
    HTMLContent
  },
  setup() {
    const { hasCurrencyToSelect, hasStoresToSelect } = useTopBar();
    const { app, $strapi } = useContext();
    const { addTags } = useCache();

    // Get Strapi data
    const topBarData: any = useAsync(() => $strapi.find('topbar'));

    // Set weekday
    const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
    const d = new Date();
    let currentDay = weekday[d.getDay()];

    // Set cache
    onMounted(() => {
      addTags([{ prefix: CacheTagPrefix.Block, value: 'topbar' }]);
    });

    return {
      hasCurrencyToSelect,
      hasStoresToSelect,
      currentDay,
      topBarData
    };
  },
});

