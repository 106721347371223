












import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'SvgImage',
  props: {
    animate: {
      type: Boolean,
      default: false
    },
    animation: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      default: '24',
    },
    height: {
      type: String,
      default: '24',
    },
    tag: {
      type: String,
      default: 'span',
    },
    label: {
      type: String,
      default: '',
    },
    role: {
      type: String,
      default: '',
    },
    offset: {
      type: String,
      default: '0'
    }
  },
});
