import Vue from 'vue'
import VueGtag from 'vue-gtag'

export default ({ app }) => {
  Vue.use(VueGtag, {
    config: { id: 'AW-743381384'},
    appName: 'HEDGE ROSE GTAG',
    pageTrackerTemplate: (to, from) => {
      return {
        page_title: to.params.slug,
        page_path: to.fullPath
      }
    }
  }, app.router)
}
