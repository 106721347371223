<template>
  <div class="customanimation bg-secondaryLight flex flex-row items-end justify-between mb-12 lg:mb-0">
    <img v-for="(item, index) in items" :key="index" :src="'footer/' + item.name + '.svg'" alt="Footer Flowers" :width="item.width" :height="item.height" :class="`hover:translate-y-4 transition duration-${index}00 animate-fade-in`"  />
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import SvgImage from '~/components/General/SvgImage.vue'
  import { animateOnScroll } from '~/helpers/headless/helpers';

  export default defineComponent({
    name: 'HeadlessFooterFlowers',
    components: {
      SvgImage
    },
    data() {
      return {
        items: [
          {name: "1", width: "77", height: "117", animate: true, animation:'animate-draw-flowers', offset: '1000'},
          {name: "2", width: "39", height: "115", animate: true, animation:'animate-draw-flowers', offset: '1000'},
          {name: "3", width: "44", height: "90", animate: true, animation:'animate-draw-flowers', offset: '1000'},
          {name: "4", width: "62", height: "80", animate: true, animation:'animate-draw-flowers-fast', offset: '1000'},
          {name: "5", width: "60", height: "72", animate: true, animation:'animate-draw-flowers', offset: '1000'},
          {name: "6", width: "56", height: "89", animate: true, animation:'animate-draw-flowers-fast', offset: '1000'},
          {name: "7", width: "75", height: "143", animate: true, animation:'animate-draw-flowers', offset: '1000'},
          {name: "8", width: "73", height: "138", animate: true, animation:'animate-draw-flowers', offset: '1000'},
          {name: "9", width: "73", height: "129", animate: true, animation:'animate-draw-flowers', offset: '1000'},
          {name: "10", width: "103", height: "153", animate: true, animation:'animate-draw-flowers-fast', offset: '1400'},
          {name: "11", width: "75", height: "95", animate: true, animation:'animate-draw-flowers', offset: '1000'},
          {name: "12", width: "46", height: "92", animate: true, animation:'animate-draw-flowers-fast', offset: '1000'},
          {name: "13", width: "77", height: "137", animate: true, animation:'animate-transition-in', offset: '0'},
          {name: "14", width: "184", height: "219", animate: true, animation:'animate-draw-flowers-fast', offset: '2000'}
        ]
      }
    },
    mounted() {
      animateOnScroll();
    }
  })
</script>

