























import LazyHydrate from 'vue-lazy-hydration';
import { useRoute, defineComponent } from '@nuxtjs/composition-api';
import { useUiState } from '~/composables';
import AppHeader from '~/components/AppHeader.vue';
import BottomNavigation from '~/components/BottomNavigation.vue';
import IconSprite from '~/components/General/IconSprite.vue';
import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';
import TopBar from '~/components/TopBar/TopBar.vue';
import HeadlessFooter from '~/components/Headless/Footer/Footer.vue'

export default defineComponent({
  name: 'homeLayout',

  components: {
    LoadWhenVisible,
    LazyHydrate,
    AppHeader,
    BottomNavigation,
    IconSprite,
    TopBar,
    HeadlessFooter,
    CartSidebar: () => import(/* webpackPrefetch: true */ '~/components/CartSidebar.vue'),
    WishlistSidebar: () => import(/* webpackPrefetch: true */ '~/modules/wishlist/components/WishlistSidebar.vue'),
    LoginModal: () => import(/* webpackPrefetch: true */ '~/modules/customer/components/LoginModal/LoginModal.vue'),
    Notification: () => import(/* webpackPrefetch: true */ '~/components/Notification.vue'),
  },

  setup() {
    const route = useRoute();
    const {
      isCartSidebarOpen, isWishlistSidebarOpen, isLoginModalOpen, toggleLoginModal,
    } = useUiState();

    return {
      isCartSidebarOpen,
      isWishlistSidebarOpen,
      isLoginModalOpen,
      toggleLoginModal,
      route,
    };
  }
});
