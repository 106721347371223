<template>
  <div class="text-primary font-serif w-screen md:w-full ">
    <div class="text-7xl md:text-2xl lg:text-7xl text-primary font-bold flex flex-row justify-between py-2 md:py-1">
      <div class="text-primary ml-4 md:ml-0">{{content.title}}</div>
      <button @click="isOpen = !isOpen" class="block md:hidden btn" type="button">
        <SvgImage v-if="!isOpen" icon="chevron_down" :label="$t('Open Navigation')" width="24" height="24" class="block md:hidden mt-2 mb-2 mr-4" />
        <SvgImage v-if="isOpen" icon="chevron_up" :label="$t('Open Navigation')" width="24" height="24" class="block md:hidden mt-2 mb-2 mr-4" />
      </button>
    </div>
    <div class="mt-2 px-4 md:px-0 md:block leading-10 text-xl md:leading-6 md:text-base" :class="isOpen ? 'block' : 'hidden'">
      <a v-for="(item, index) in content.items" :href="item.link" target="_blank" :key="index" >
        <SvgImage  class="text-primary-light mr-8 md:mr-2 lg:mr-8 hover:opacity-50 transition duration-150 ease-out"
          :icon="item.icon"
          :label="$t(item.label)"
          width="30"
          height="30"/>
      </a>
    </div>
    <p class="text-xs font-sans mt-2 hover:text-primaryLight50 cursor-cell group text-center md:text-left">made with <span class="text-primary group-hover:text-primaryLight50">&hearts;</span> by <a class="group-hover:text-primary group-hover:underline" href="https://headless.uk" target="_blank">headless.uk</a></p>
    <span class="text-xs font-sans mt-6 block text-center md:text-left">© Hedge Rose Flowers Ltd. VAT number GB347382873</span>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import SvgImage from '~/components/General/SvgImage.vue';

  export default defineComponent({
    name: 'HeadlessSocialColumn',
    components: { SvgImage },
    props: {
      content: {
        type: Object,
        default: null
      }
    },
    data() {
      return {
        isOpen: false
      }
    }
  });
</script>
