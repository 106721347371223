<template>
  <div class="text-primary font-serif w-screen md:w-full">
    <div class="text-7xl md:text-2xl lg:text-7xl text-primary font-bold flex flex-row justify-between py-2 md:py-1" >
      <div class="text-primary ml-4 md:ml-0">{{content.title}}</div>
      <button type="button" @click="isOpen = !isOpen" class="flex absolute w-full justify-end md:hidden btn" >
        <SvgImage v-if="!isOpen" icon="chevron_down" :label="$t('Open Navigation')" width="24" height="24" class="md:hidden mt-2 mb-2 mr-4" />
        <SvgImage v-if="isOpen" icon="chevron_up" :label="$t('Open Navigation')" width="24" height="24" class="md:hidden mt-2 mb-2 mr-4" />
      </button>
    </div>
    <ul class="md:block leading-10 text-xl md:leading-6 md:text-base lg:pt-4 list-none pl-0" :class="isOpen ? 'block' : 'hidden'">
      <li v-for="(item, index) in content.items" :key="index">
        <NuxtLink v-if="!item.external" class="text-body font-sans text-blackSecondary px-4 md:px-0 hover:text-primary" :to="item.link">{{item.name}}</NuxtLink>
        <a v-if="item.external" class="text-body font-sans text-blackSecondary px-4 md:px-0 hover:text-primary" :href="item.link">{{item.name}}</a>
      </li>
    </ul>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import SvgImage from '~/components/General/SvgImage.vue';

  export default defineComponent({
    name: 'HeadlessFooterColumn',
    components: { SvgImage },
    props: {
      content: {
        type: Object,
        default: null
      }
    },
    data() {
      return {
        isOpen: false
      }
    }
  });
</script>
