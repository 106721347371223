export function getImage(src, { modifiers, baseURL } = {}, { options, nuxtContext, $img }) {
  const { width, height, format, fit, ...providerModifiers } = modifiers
  const storageUrl = 'https://storage.googleapis.com/outeredge/staging/hedgerosevsf',
        cdn = 'https://assets.hedgerose.uk',
        setWidth = width > 0 ?  `?w=${width}` : '',
        setHeight = height > 0 ? `&h=${height}` : '',
        thumbnail = fit === 'square' ? `?thumbnail=${width}` : '',
        parameters = thumbnail ? thumbnail : setWidth + setHeight;

  return {
    url: src.includes(storageUrl) ? src.replace(storageUrl, cdn) + parameters : src + parameters
  }
}
