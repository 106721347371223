




































import LazyHydrate from 'vue-lazy-hydration';

import { SfButton, SfCharacteristic, SfImage } from '@storefront-ui/vue';

import { ref, defineComponent } from '@nuxtjs/composition-api';
import StoresModal from '~/components/StoreSwitcher/StoresModal.vue';
import { useConfig } from '~/composables';

export default defineComponent({
  name: 'StoreSwitcher',
  components: {
    StoresModal,
    SfButton,
    SfCharacteristic,
    SfImage,
    LazyHydrate,
  },
  setup() {
    const { config } = useConfig();

    const isLangModalOpen = ref(false);

    return {
      isLangModalOpen,
      storeConfig: config,
    };
  },
});
