


















































import {
  defineComponent, onMounted, computed, PropType,
} from '@nuxtjs/composition-api';
import {
  SfButton,
  SfList,
  SfBottomModal,
  SfCharacteristic,
  SfImage,
} from '@storefront-ui/vue';
import { StoreConfig } from '~/modules/GraphQL/types';
import { AvailableStores, useStore } from '~/composables';

export default defineComponent({
  name: 'StoresModal',
  components: {
    SfButton,
    SfList,
    SfBottomModal,
    SfCharacteristic,
    SfImage,
  },
  props: {
    isLangModalOpen: Boolean,
    storeConfig: {
      type: Object as PropType<StoreConfig>,
      default: (): StoreConfig => ({}),
    },
  },
  emits: ['closeModal'],
  setup() {
    const {
      stores,
      change: changeStore,
      load: loadStores,
    } = useStore();

    const availableStores = computed<AvailableStores>(() => stores.value ?? []);

    onMounted(() => {
      if (stores.value && stores.value?.length) return;
      loadStores();
    });

    return {
      availableStores,
      changeStore,
    };
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
  },
});
