import Vue from 'vue'

import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'

/* Plugins */

import nuxt_plugin_plugin_81d61f60 from 'nuxt_plugin_plugin_81d61f60' // Source: ./composition-api/plugin.mjs (mode: 'all')
import nuxt_plugin_recaptcha_0b816826 from 'nuxt_plugin_recaptcha_0b816826' // Source: ./recaptcha.js (mode: 'all')
import nuxt_plugin_markdownit_0670a680 from 'nuxt_plugin_markdownit_0670a680' // Source: ./markdownit.js (mode: 'all')
import nuxt_plugin_nuxtplugin08ec1aeb_1f3d1a92 from 'nuxt_plugin_nuxtplugin08ec1aeb_1f3d1a92' // Source: ./nuxt.plugin.08ec1aeb.js (mode: 'server')
import nuxt_plugin_vuescrollto_1b546fcb from 'nuxt_plugin_vuescrollto_1b546fcb' // Source: ./vue-scrollto.js (mode: 'client')
import nuxt_plugin_cookieuniversalnuxt_3d93dfb4 from 'nuxt_plugin_cookieuniversalnuxt_3d93dfb4' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_pluginutils_4f180d7d from 'nuxt_plugin_pluginutils_4f180d7d' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginseo_9cf187de from 'nuxt_plugin_pluginseo_9cf187de' // Source: ./nuxt-i18n/plugin.seo.js (mode: 'all')
import nuxt_plugin_pluginrouting_c169a0f0 from 'nuxt_plugin_pluginrouting_c169a0f0' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_3aef28e7 from 'nuxt_plugin_pluginmain_3aef28e7' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_distplugin73c15d18_7b6c8718 from 'nuxt_plugin_distplugin73c15d18_7b6c8718' // Source: ./dist.plugin.73c15d18.mjs (mode: 'all')
import nuxt_plugin_image_f8ffad0e from 'nuxt_plugin_image_f8ffad0e' // Source: ./image.js (mode: 'all')
import nuxt_plugin_corepluginc922d85e_2e6d98ea from 'nuxt_plugin_corepluginc922d85e_2e6d98ea' // Source: ./core.plugin.c922d85e.ts (mode: 'all')
import nuxt_plugin_pluginse2etesting62ef1a71_4183db52 from 'nuxt_plugin_pluginse2etesting62ef1a71_4183db52' // Source: ./plugins.e2e-testing.62ef1a71.js (mode: 'all')
import nuxt_plugin_deviceplugin_3e5933ee from 'nuxt_plugin_deviceplugin_3e5933ee' // Source: ./device.plugin.js (mode: 'all')
import nuxt_plugin_workbox_3e9f0bcc from 'nuxt_plugin_workbox_3e9f0bcc' // Source: ./workbox.js (mode: 'client')
import nuxt_plugin_metaplugin_c439f1a6 from 'nuxt_plugin_metaplugin_c439f1a6' // Source: ./pwa/meta.plugin.js (mode: 'all')
import nuxt_plugin_iconplugin_baeaa6be from 'nuxt_plugin_iconplugin_baeaa6be' // Source: ./pwa/icon.plugin.js (mode: 'all')
import nuxt_plugin_httpserver_433e8aeb from 'nuxt_plugin_httpserver_433e8aeb' // Source: ./http.server.js (mode: 'server')
import nuxt_plugin_http_99e343dc from 'nuxt_plugin_http_99e343dc' // Source: ./http.js (mode: 'all')
import nuxt_plugin_strapi_081b63f1 from 'nuxt_plugin_strapi_081b63f1' // Source: ./strapi.js (mode: 'all')
import nuxt_plugin_tokenexpired_ca1e20ca from 'nuxt_plugin_tokenexpired_ca1e20ca' // Source: ../plugins/token-expired (mode: 'all')
import nuxt_plugin_i18n_6a80ea94 from 'nuxt_plugin_i18n_6a80ea94' // Source: ../plugins/i18n (mode: 'all')
import nuxt_plugin_fcPlugin_5a846bc6 from 'nuxt_plugin_fcPlugin_5a846bc6' // Source: ../plugins/fcPlugin (mode: 'all')
import nuxt_plugin_dompurify_9e093c4a from 'nuxt_plugin_dompurify_9e093c4a' // Source: ../plugins/dompurify (mode: 'all')
import nuxt_plugin_storeConfigPlugin_234467e0 from 'nuxt_plugin_storeConfigPlugin_234467e0' // Source: ../plugins/storeConfigPlugin (mode: 'all')
import nuxt_plugin_gtag_6a810249 from 'nuxt_plugin_gtag_6a810249' // Source: ../plugins/gtag (mode: 'client')
import nuxt_plugin_vuemasonry_087bf870 from 'nuxt_plugin_vuemasonry_087bf870' // Source: ../plugins/vue-masonry (mode: 'client')
import nuxt_plugin_meta_1f1c9f62 from 'nuxt_plugin_meta_1f1c9f62' // Source: ./composition-api/meta.mjs (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root.$options.$nuxt
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"Hedge Rose","meta":[{"charset":"utf8"},{"name":"viewport","content":"width=device-width, initial-scale=1, maximum-scale=2"},{"hid":"description","name":"description","content":"\u003C!-- ALL-CONTRIBUTORS-BADGE:START - Do not remove or modify this section --\u003E [![All Contributors](https:\u002F\u002Fimg.shields.io\u002Fbadge\u002Fall_contributors-21-orange.svg?style=flat-square)](#contributors-) \u003C!-- ALL-CONTRIBUTORS-BADGE:END --\u003E"},{"name":"generator","content":"Vue Storefront 2"}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.ico"}],"style":[],"script":[]},

    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_81d61f60 === 'function') {
    await nuxt_plugin_plugin_81d61f60(app.context, inject)
  }

  if (typeof nuxt_plugin_recaptcha_0b816826 === 'function') {
    await nuxt_plugin_recaptcha_0b816826(app.context, inject)
  }

  if (typeof nuxt_plugin_markdownit_0670a680 === 'function') {
    await nuxt_plugin_markdownit_0670a680(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_nuxtplugin08ec1aeb_1f3d1a92 === 'function') {
    await nuxt_plugin_nuxtplugin08ec1aeb_1f3d1a92(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuescrollto_1b546fcb === 'function') {
    await nuxt_plugin_vuescrollto_1b546fcb(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_3d93dfb4 === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_3d93dfb4(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_4f180d7d === 'function') {
    await nuxt_plugin_pluginutils_4f180d7d(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginseo_9cf187de === 'function') {
    await nuxt_plugin_pluginseo_9cf187de(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_c169a0f0 === 'function') {
    await nuxt_plugin_pluginrouting_c169a0f0(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_3aef28e7 === 'function') {
    await nuxt_plugin_pluginmain_3aef28e7(app.context, inject)
  }

  if (typeof nuxt_plugin_distplugin73c15d18_7b6c8718 === 'function') {
    await nuxt_plugin_distplugin73c15d18_7b6c8718(app.context, inject)
  }

  if (typeof nuxt_plugin_image_f8ffad0e === 'function') {
    await nuxt_plugin_image_f8ffad0e(app.context, inject)
  }

  if (typeof nuxt_plugin_corepluginc922d85e_2e6d98ea === 'function') {
    await nuxt_plugin_corepluginc922d85e_2e6d98ea(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginse2etesting62ef1a71_4183db52 === 'function') {
    await nuxt_plugin_pluginse2etesting62ef1a71_4183db52(app.context, inject)
  }

  if (typeof nuxt_plugin_deviceplugin_3e5933ee === 'function') {
    await nuxt_plugin_deviceplugin_3e5933ee(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_workbox_3e9f0bcc === 'function') {
    await nuxt_plugin_workbox_3e9f0bcc(app.context, inject)
  }

  if (typeof nuxt_plugin_metaplugin_c439f1a6 === 'function') {
    await nuxt_plugin_metaplugin_c439f1a6(app.context, inject)
  }

  if (typeof nuxt_plugin_iconplugin_baeaa6be === 'function') {
    await nuxt_plugin_iconplugin_baeaa6be(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_httpserver_433e8aeb === 'function') {
    await nuxt_plugin_httpserver_433e8aeb(app.context, inject)
  }

  if (typeof nuxt_plugin_http_99e343dc === 'function') {
    await nuxt_plugin_http_99e343dc(app.context, inject)
  }

  if (typeof nuxt_plugin_strapi_081b63f1 === 'function') {
    await nuxt_plugin_strapi_081b63f1(app.context, inject)
  }

  if (typeof nuxt_plugin_tokenexpired_ca1e20ca === 'function') {
    await nuxt_plugin_tokenexpired_ca1e20ca(app.context, inject)
  }

  if (typeof nuxt_plugin_i18n_6a80ea94 === 'function') {
    await nuxt_plugin_i18n_6a80ea94(app.context, inject)
  }

  if (typeof nuxt_plugin_fcPlugin_5a846bc6 === 'function') {
    await nuxt_plugin_fcPlugin_5a846bc6(app.context, inject)
  }

  if (typeof nuxt_plugin_dompurify_9e093c4a === 'function') {
    await nuxt_plugin_dompurify_9e093c4a(app.context, inject)
  }

  if (typeof nuxt_plugin_storeConfigPlugin_234467e0 === 'function') {
    await nuxt_plugin_storeConfigPlugin_234467e0(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_gtag_6a810249 === 'function') {
    await nuxt_plugin_gtag_6a810249(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuemasonry_087bf870 === 'function') {
    await nuxt_plugin_vuemasonry_087bf870(app.context, inject)
  }

  if (typeof nuxt_plugin_meta_1f1c9f62 === 'function') {
    await nuxt_plugin_meta_1f1c9f62(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    app,
    router
  }
}

export { createApp, NuxtError }
