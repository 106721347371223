

















import { defineComponent } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import type { NuxtError } from '@nuxt/types';
import PrimaryButton  from '~/components/Headless/PrimaryButton.vue';
import CircleGradient from '~/components/Headless/Watermarks/CircleGradient.vue';

export default defineComponent({
  components: {
    PrimaryButton,
    CircleGradient
  },
  props: {
    error: {
      type: Object as PropType<NuxtError>,
      required: true,
    },
  },
});
