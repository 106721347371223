<template functional>
  <div class="block">
    <template v-if="props.link">
      <a :href="props.link" class="group relative inline-block md:min-w-button">
        <span v-html="props.tulip" :class="props.tulipClassNames" class="-left-2 duration-200 -rotate-45 group-hover:bottom-6 group-active:bottom-9"></span>
        <span v-html="props.tulip" :class="props.tulipClassNames" class="left-1 duration-100 group-hover:bottom-8 group-active:bottom-9"></span>
        <span v-html="props.tulip" :class="props.tulipClassNames" class="left-12 -duration-300 rotate-12 group-hover:bottom-6 group-active:bottom-7"></span>
        <span v-html="props.tulip" :class="props.tulipClassNames" class="right-9 duration-150 -rotate-12 group-hover:bottom-9 group-active:bottom-8"></span>
        <span v-html="props.tulip" :class="props.tulipClassNames" class="right-2 duration-200 rotate-10 group-hover:bottom-10 group-active:bottom-9"></span>
        <span v-html="props.tulip" :class="props.tulipClassNames" class="-right-4 duration-400 rotate-12 group-hover:bottom-5 group-active:bottom-6"></span>
        <span :class="props.classNames">
          <slot />
        </span>
      </a>
    </template>
    <template v-else-if="props.to">
      <NuxtLink
      class="group relative inline-block md:min-w-button"
      :to="props.to">
        <span v-html="props.tulip" :class="props.tulipClassNames" class="left-0 duration-200 -rotate-45 group-hover:bottom-6 group-active:bottom-9"></span>
        <span v-html="props.tulip" :class="props.tulipClassNames" class="left-6 duration-100 group-hover:bottom-8 group-active:bottom-9"></span>
        <span v-html="props.tulip" :class="props.tulipClassNames" class="left-14 -duration-300 rotate-12 group-hover:bottom-6 group-active:bottom-7"></span>
        <span v-html="props.tulip" :class="props.tulipClassNames" class="right-12 duration-150 -rotate-12 group-hover:bottom-9 group-active:bottom-8"></span>
        <span v-html="props.tulip" :class="props.tulipClassNames" class="right-6 duration-200 rotate-10 group-hover:bottom-8 group-active:bottom-7"></span>
        <span v-html="props.tulip" :class="props.tulipClassNames" class="right-0 duration-400 rotate-12 group-hover:bottom-5 group-active:bottom-6"></span>
        <span :class="props.classNames">
          <slot />
        </span>
      </NuxtLink>
    </template>
    <template v-else>
      <button class="relative inline-block md:min-w-button" :disabled="props.disabled" :class="(props.type === 'wide' ? 'min-w-[210px]' : '') + (props.disabled ? ' opacity-50' : ' group')"
        v-bind="data.attrs"
        v-on="!props.disabled ? listeners : {}"
      >
        <span v-html="props.tulip" :class="props.tulipClassNames" class="-left-1 duration-200 -rotate-45 group-hover:bottom-6 group-active:bottom-9"></span>
        <span v-html="props.tulip" :class="props.tulipClassNames" class="left-4 duration-100 group-hover:bottom-8 group-active:bottom-9"></span>
        <span v-html="props.tulip" :class="props.tulipClassNames" class="left-12 duration-300 rotate-12 group-hover:bottom-6 group-active:bottom-7"></span>
        <span v-html="props.tulip" v-if="props.type == 'wide'" :class="props.tulipClassNames" class="right-16 duration-300 rotate-16 group-hover:bottom-5 group-active:bottom-4"></span>
        <span v-html="props.tulip" :class="props.tulipClassNames" class="right-9 duration-150 -rotate-12 group-hover:bottom-9 group-active:bottom-8"></span>
        <span v-html="props.tulip" :class="props.tulipClassNames" class="right-2 duration-200 rotate-12 group-hover:bottom-10 group-active:bottom-9"></span>
        <span v-html="props.tulip" :class="props.tulipClassNames" class="-right-2 duration-400 rotate-20 group-hover:bottom-5 group-active:bottom-6"></span>
        <span :class="props.classNames">
          <slot />
        </span>
      </button>
    </template>
  </div>
</template>
<script>
  import { defineComponent } from '@nuxtjs/composition-api';

  export default defineComponent({
    name: "PrimaryButton",
    props: {
      classNames: {
        type: String,
        default: 'relative duration-100 ease-linear bg-primary rounded-2xl text-white font-bold uppercase py-3 px-8 block text-center group-hover:shadow-md group-hover:rounded-t-none'
      },
      tulip: {
        type: String,
        default: `<svg xmlns="http://www.w3.org/2000/svg" width="31" height="52" viewBox="0 0 31 52" fill="none">
          <path d="M13.9741 51.3105V32.6931H16.3834V51.3105C16.3834 51.3105 16.3834 52 15.2096 52C14.0359 52 13.9741 51.3105 13.9741 51.3105Z" fill="#3E4E39"/>
          <path d="M6.2243 30.5067V26.7741C5.77284 19.3999 7.02736 15.7047 12.7294 10.17C13.6451 9.36771 14.1967 8.94358 15.2994 8.23935C15.2994 8.23935 13.5325 7.14529 10.4004 5.98688C7.26829 4.82846 5.01959 4.50668 5.01959 4.50668C3.65214 4.25945 2.95562 4.17704 1.80715 4.1206C1.37925 4.13179 1.17186 4.17334 0.843474 4.31367C0.451285 4.54517 0.267188 4.69614 0.0403578 5.02159C-0.0128841 5.24721 -0.0140181 5.38697 0.0403578 5.66516L0.120669 6.0513L0.28129 6.50179L0.682845 7.66021L1.00409 8.68991L1.16471 9.39783L1.32533 10.2345L1.40564 11.0067V14.8681L1.24502 15.9622L1.0844 16.7345L0.763156 18.0216L0.522223 18.7939C0.522223 18.7939 0.361556 19.6304 0.361556 19.6948C0.361556 19.7592 0.20098 20.7889 0.20098 20.7889V22.0117L0.361601 23.1058L0.682845 24.1998L1.24498 25.4869C1.51459 25.9348 1.68975 26.2158 2.04813 26.7741C2.04813 26.7741 2.52995 27.4176 2.77093 27.6751C3.01191 27.9326 3.17244 28.1255 3.17244 28.1255L3.57404 28.5117L4.13617 29.0265L4.7787 29.5414L6.2243 30.5067Z" fill="#5B6F55"/>
          <path d="M9.71762 32.0513C7.95078 31.5305 6.1442 30.42 6.1442 30.42V26.7838C6.1442 26.7838 5.9819 23.3425 6.30651 20.6803C6.63113 18.0181 8.41651 15.0313 8.41651 15.0313C8.41651 15.0313 9.39036 13.3431 11.338 11.2653C13.2857 9.18752 15.3146 8.01877 15.3146 8.01877C15.3146 8.01877 16.5319 7.30452 18.6419 6.39549C20.7519 5.48645 22.375 5.03194 22.375 5.03194C22.375 5.03194 22.7807 4.83714 24.0792 4.57742C25.3777 4.3177 25.8646 4.25276 25.8646 4.25276C25.8646 4.25276 26.9196 4.1229 27.4877 4.05797L29.1919 3.86318C29.1919 3.86318 29.5977 3.86318 30.0034 4.05797C30.4092 4.25276 30.6702 4.52736 30.6702 4.52736C30.7275 4.5775 30.9093 4.74948 30.9773 4.96701C31.0584 5.22673 30.8961 5.48645 30.8961 5.48645C30.8961 5.48645 30.4904 6.33056 30.1657 7.23959C29.8411 8.14863 29.76 8.66808 29.76 8.66808C29.5977 9.57711 29.4354 11.59 29.4354 12.5639V13.6678C29.4354 13.7976 29.5977 14.7716 29.5977 14.7716L29.76 15.8105L29.9223 16.6546L30.1657 17.6935L30.4092 18.6025L30.5715 19.7713C30.5715 19.7713 30.6527 21.4595 30.328 23.4724C30.0034 25.4852 28.7861 26.7189 28.7861 26.7189C28.7861 26.7189 28.0285 27.9325 26.2617 29.4127C24.4948 30.8929 22.6996 31.5238 22.6996 31.5238C22.6996 31.5238 21.7257 31.9134 20.1027 32.3679C18.4796 32.8224 17.1811 32.8874 17.1811 32.8874C17.1811 32.8874 16.5319 32.9523 15.3957 32.9523C14.2596 32.9523 13.6104 32.8874 13.6104 32.8874C13.6104 32.8874 11.4845 32.5721 9.71762 32.0513Z" fill="#3E4E39"/>
          <path d="M4.97927 4.50674C4.97927 4.50674 5.1399 4.05625 5.1399 2.70476C5.1399 1.35328 5.30052 0.774071 5.30052 0.774071C5.30052 0.774071 5.58161 0.452287 5.94301 0.25922C6.3044 0.066152 6.74611 0.0661502 6.74611 0.0661502C6.74611 0.0661502 7.54922 0.00179381 8.75389 0.130507C9.95855 0.25922 10.6813 0.452289 10.6813 0.452289C10.6813 0.452289 11.886 0.709715 13.171 1.16021C14.456 1.6107 15.4197 2.0612 15.4197 2.0612C15.4197 2.0612 14.6969 2.57426 14.0544 3.02476L12.7694 4.05446L11.8057 4.95545C11.3238 5.40594 10.601 6.11386 10.601 6.11386C10.601 6.11386 9.47668 5.72773 7.87047 5.21287C6.26425 4.69802 4.97927 4.50674 4.97927 4.50674Z" fill="#5B6F55"/>
          <path d="M12.7694 7.01485C11.2435 6.28119 10.601 6.11386 10.601 6.11386C10.601 6.11386 11.3238 5.27902 12.8497 3.92753C14.3756 2.57605 15.5803 1.99684 15.5803 1.99684C15.5803 1.99684 17.1865 1.22456 19.5155 0.580996C21.8446 -0.0625688 23.6917 0.00178768 23.6917 0.00178768C23.6917 0.00178768 24.2539 0.00178768 24.8161 0.259213C25.3782 0.516639 25.5389 0.838421 25.5389 0.838421C25.5389 0.838421 25.7798 1.1602 25.6995 2.25426C25.6192 3.34832 25.6995 4.31188 25.6995 4.31188C25.6995 4.31188 23.6917 4.56931 20.2383 5.85644C16.785 7.14356 15.0984 8.17327 15.0984 8.17327C15.0984 8.17327 14.2953 7.74851 12.7694 7.01485Z" fill="#7C8C77"/>
        </svg>`
      },
      tulipClassNames: {
        type: String,
        default: 'absolute ease-linear bottom-0 opacity-0 group-hover:opacity-100'
      },
      to: {
        type: [String, Object],
        default: null
      },
      link: {
        type: String,
        default: null
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      type: {
        type: String,
        default: 'normal'
      }
    }
  });
</script>
