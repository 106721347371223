
















import { SfButton } from '@storefront-ui/vue';
import {
  ref,
  computed,
  defineComponent,
} from '@nuxtjs/composition-api';
import { useMagentoConfiguration } from '~/composables/useMagentoConfiguration';
import CurrenciesModal from './CurrencySelector/CurrenciesModal.vue';

export default defineComponent({
  name: 'CurrencySelector',
  components: {
    SfButton,
    CurrenciesModal,
  },
  setup() {
    const {
      selectedCurrency,
      selectedLocale,
    } = useMagentoConfiguration();

    const currentCurrencySymbol = computed(() => {
      try {
        return (0).toLocaleString(
          selectedLocale.value.replace(/[!"$-/:-?[\]^_`{-~]/, '-'),
          {
            style: 'currency',
            currency: selectedCurrency.value,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          },
        )
          .replace(/\d/g, '')
          .trim();
      } catch {
        return selectedLocale.value;
      }
    });

    const isCurrencyModalOpen = ref(false);

    return {
      currentCurrencySymbol,
      isCurrencyModalOpen,
      selectedCurrency,
      selectedLocale,
    };
  },
});
