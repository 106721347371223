import Vue from 'vue'
import Hookable from 'hookable'
import reqURL from 'requrl'
import { joinURL } from 'ufo'
import type { NuxtHTTPInstance } from '@nuxt/http'
import type { NuxtCookies } from 'cookie-universal-nuxt'
import type {
  NuxtStrapiQueryParams,
  StrapiOptions
} from '../types'

export class Strapi extends Hookable {
  private state: { user: null | any }
  $cookies: NuxtCookies
  $http: NuxtHTTPInstance
  options: StrapiOptions

  constructor (ctx, options: StrapiOptions) {
    super()

    ctx.$config = ctx.$config || {} // fallback for Nuxt < 2.13
    const runtimeConfig = ctx.$config.strapi || {}
    this.$cookies = ctx.app.$cookies
    this.$http = ctx.$http.create({})
    this.options = options

    this.state = Vue.observable({ user: null })

    const url = runtimeConfig.url || this.options.url
    if (process.server && ctx.req && url.startsWith('/')) {
      this.$http.setBaseURL(joinURL(reqURL(ctx.req), url))
    } else {
      this.$http.setBaseURL(url)
    }
    this.$http.onError((err) => {
      if (!err.response) {
        this.callHook('error', err)
        return
      }

      const { response: { data: { message: msg } } }: any = err

      let message
      if (Array.isArray(msg)) {
        message = msg[0].messages[0].message
      } else if (typeof msg === 'object' && msg !== null) {
        message = msg.message
      } else {
        message = msg
      }

      err.message = message;
      (err as any).original = (err.response as any).data
      this.callHook('error', err)
    })
  }

  find<T = any, E = string> (entity: E, searchParams?: NuxtStrapiQueryParams): Promise<T> {
    return this.$http.$get<T>(`/${entity}`, { searchParams })
  }

  count<T = any, E = string> (entity: E, searchParams?: NuxtStrapiQueryParams): Promise<T> {
    return this.$http.$get<T>(`/${entity}/count`, { searchParams })
  }

  findOne<T = any, E = string> (entity: E, id: string): Promise<T> {
    return this.$http.$get<T>(`/${entity}/${id}`)
  }

  create<T = any, E = string> (entity: E, data: NuxtStrapiQueryParams): Promise<T> {
    return this.$http.$post<T>(`/${entity}`, data)
  }

  update<T = any, E = string> (entity: E, id: string, data: NuxtStrapiQueryParams): Promise<T> {
    if (typeof id === 'object') {
      data = id
      id = undefined
    }

    const path = [entity, id].filter(Boolean).join('/')
    return this.$http.$put(`/${path}`, data)
  }

  delete<T = any, E = string> (entity: E, id: string): Promise<T> {
    const path = [entity, id].filter(Boolean).join('/')
    return this.$http.$delete(`/${path}`)
  }

  getCookie (name: string): string {
    return this.$cookies.get(name)
  }

  setCookie (name: string, value: string): void {
    this.$cookies.set(name, value, {
     maxAge: 60 * 60 * 24 * 7
    })
  }
}
